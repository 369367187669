module.exports={
    "PLATFORM_ID": "geotrek-rando",
    "API_URL": "tests/dataset",
    "BACKOFFICE_URL": "",
    "ENABLE_HTML_MODE": false,
    "LOGO_FILE": "",
    "FAVICON": "",
    "SHOW_HOME": true,
    "HOME_TEMPLATE_FILE": {
        "fr": "",
        "en": "",
        "es": "",
        "ca": "",
        "de": "",
        "it": "",
        "nl": ""
    },
    "SHOW_FOOTER": false,
    "FOOTER_TEMPLATE_FILE": "",
    "HEADER_TEMPLATE_FILE": "",
    "PICTURE_TREK_SLIDES_CAPTION_ORDER": ["legend", "author"],
    "PICTURE_TREK_CAPTION_ORDER": ["author"],
    "PICTURE_POI_SLIDES_CAPTION_ORDER": ["legend", "author"],
    "PICTURE_POI_CAPTION_ORDER": ["author"],
    "ENABLE_DISTRICTS_FILTERING": true,
    "ENABLE_CITIES_FILTERING": true,
    "ENABLE_STRUCTURE_FILTERING": true,
    "GEO_FILTERS_AUTO_CLOSE": false,
    "RULES_FLAT_PAGES_ID": "",
    "FAVORITES_ICON": "heart",
    "SHARE_ICON": "share-alt",
    "PLACEHOLDER_IMAGE": "",
    "DISPLAY_CREDITS_ON_IMAGE": true,
    "DEFAULT_LANGUAGE": "en",
    "AVAILABLE_LANGUAGES": {
        "fr": "Français",
        "en": "English",
        "es": "Español",
        "ca": "Català",
        "de": "Deutsch",
        "it": "Italiano",
        "nl": "Nederlands"
    },
    "ENABLED_LANGUAGES": ["fr", "en", "es", "ca", "de", "it", "nl"],
    "ENABLE_TREKS": true,
    "ENABLE_TOURISTIC_CONTENT": true,
    "ENABLE_TOURISTIC_EVENTS": true,
    "ENABLE_DIVES": false,
    "DEFAULT_ACTIVE_CATEGORIES": ["T"],
    "LIST_EXCLUDE_CATEGORIES": [],
    "ENABLE_UNIQUE_CAT": false,
    "RANDOMIZE_RESULTS": false,
    "PDF_LINK_IN_DOWNLOAD": false,
    "FILTERS_DEFAULT_OPEN": false,
    "DEFAULT_INTEREST": "pois",
    "FACEBOOK_APP_ID": "",
    "TWITTER_ID": "",
    "DEFAULT_SHARE_IMG": "",
    "GOOGLE_ANALYTICS_ID": "",
    "PERMANENT_TILELAYERS_NAME": "Carte",
    "PERMANENT_TILELAYERS": [
        {
            "LAYER_URL": "//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            "OPTIONS": {
                "attribution": "(c) OpenstreetMap",
                "minZoom": 8,
                "maxZoom": 17
            }
        }
    ],
    "ORTHOPHOTO_TILELAYERS_NAME": "Ortho",
    "ORTHOPHOTO_TILELAYERS": {
        "LAYER_URL": "//{s}.tile.mapbox.com/v3/makina-corpus.i3p1001l/{z}/{x}/{y}.png",
        "OPTIONS": {
            "id": "satellite",
            "attribution": "(c) MapBox Satellite"
        }
    },
    "OPTIONAL_TILELAYERS_NAME": "Optional",
    "OPTIONAL_TILELAYERS": [],

    "SENSITIVE_TILELAYER": false,
    "SENSITIVE_FILE": "sensitiveareas.geojson",
    "SENSITIVE_DEFAULT_ICON": "",
    "SENSITIVE_LAYER_STYLE": {},

    "INFRASTRUCTURES_TILELAYER": false,
    "INFRASTRUCTURES_FILE": "infrastructures.geojson",
    "INFRASTRUCTURES_DEFAULT_ICON": "",
    "INFRASTRUCTURES_LAYER_STYLE": {},

    "SIGNAGES_TILELAYER": false,
    "SIGNAGES_FILE": "signages.geojson",
    "SIGNAGES_DEFAULT_ICON": "",
    "SIGNAGES_LAYER_STYLE": {},

    "LEAFLET_CONF": {
        "CENTER_LATITUDE": 44.83,
        "CENTER_LONGITUDE": 6.34,
        "DEFAULT_ZOOM": 12,
        "DEFAULT_MIN_ZOOM": 8,
        "DEFAULT_MAX_ZOOM": 17
    },
    "MAP_BOUNDS_CONSTRAINTS": null,
    "TREKS_TO_GEOJSON_ZOOM_LEVEL" : 14,
    "UPDATE_MAP_ON_FILTER": false,
    "ACTIVE_MINIMAP": true,
    "MINIMAP_ZOOM": {
        "MINI": 0,
        "MAX": 12
    },
    "MINIMAP_OFFSET": -3,
    "IMPERIAL_SCALE": false,
    "ALWAYS_HIGHLIGHT_TREKS": false,
    "SHOW_FILTERS_ON_MAP": true,
    "FILTER_BY_VIEWPORT_DEFAULT": false,
    "POI_EXPANDED": false,
    "DISPLAY_ASIDES_COUNTERS": false,
    "NEAR_ELEMENTS_CATEGORIES": false,
    "SHOW_ARROWS_ON_ROUTE": false,
    "CENTERON_FITS_BOUNDS": false,
    "MARKER_BASE_ICON": "",
    "POI_BASE_ICON": {
        "iconUrl": null,
        "iconSize": [32, 32]
    },
    "SERVICE_BASE_ICON": "",
    "DEPARTURE_ICON": "",
    "ARRIVAL_ICON": "",
    "DEPARTURE_ARRIVAL_ICON": "",
    "API_DIR": "api",
    "TREKS_DIR": "treks",
    "DIVES_DIR": "dives",
    "TREKS_FILE": "treks.geojson",
    "POI_FILE": "pois.geojson",
    "SERVICES_FILE": "services.geojson",
    "FLAT_FILE": "flatpages.geojson",
    "TOURISTIC_EVENTS_DIR": "touristicevents",
    "TOURISTIC_EVENTS_FILE": "touristicevents.geojson",
    "TOURISTIC_CONTENTS_DIR": "touristiccontents",
    "TOURISTIC_CONTENTS_FILE": "touristiccontents.geojson",
    "DIVES_FILE": "dives.geojson",
    "DEM_FILE": "dem.json",
    "PROFILE_FILE": "profile.json",
    "WARNING_CAT_DIR": "feedback",
    "WARNING_CAT_FILE": "categories.json",
    "WARNING_OPT_FILE": "options.json",
    "WARNING_SUBMIT_URL": "reports/report",
    "STYLES_CONFIG_FILE": "styles.json",
    "APPROVED_BIG": "images/approved-big.png",
    "APPROVED_SMALL": "images/approved-small.png",
    "APPROVED_LABEL": "Approuved",
    "DURATION_FILTER": [
        { "id": 0, "label": "<1/2 J"},
        { "id": 10, "label": "1/2 J"},
        { "id": 24, "label": "1 J"},
        { "id": 999, "label": "> 1 J"}
    ],
    "ASCENT_FILTER":  [
        { "id": 0, "label": "<300m"},
        { "id": 300, "label": "300m"},
        { "id": 600, "label": "600m"},
        { "id": 1000, "label": "1000m"},
        { "id": 9999, "label": ">1000m"}
    ],
    "LENGTH_FILTER":  [
        { "id": 0, "label": "<10km"},
        { "id": 10000, "label": "10km"},
        { "id": 20000, "label": "20km"},
        { "id": 30000, "label": "30km"},
        { "id": 99999, "label": ">30km"}
    ],
    "DEPTH_FILTER": [
        { "id": 0, "label": "<12m"},
        { "id": 12, "label": "12m"},
        { "id": 20, "label": "20m"},
        { "id": 40, "label": "40m"},
        { "id": 999, "label": ">40m"}
    ],
    "ASIDE_PANEL_FOLDED_BY_DEFAULT": false
}
